import {
  Box,
  Card,
  CardProps,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { Markdown } from "@pimo/pimo-components";
import { ReactNode } from "react";

export type ComponentTitleCardProps = {
  title?: string;
  /** Component displayed in the right corner */
  endSlot?: ReactNode;
  /** Component to be displayed in the left corner */
  startSlot?: ReactNode;
  cardProps?: CardProps;
  infoText?: string;
  titleProps?: TypographyProps;
};

export const IneffectiveControlsComponentTitleCard: PimoReactComponent<
  ComponentTitleCardProps
> = ({
  title,
  endSlot,
  startSlot,
  cardProps = {},
  infoText,
  titleProps = {},
}) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        boxShadow: "none",
        height: "100%",
        width: "100%",
        "@media print": {
          border: "1px solid rgba(0, 0, 0, 0.12)",
        },
        borderBottom: "1px solid",
        borderColor: theme.palette.secondary.main,
        p: 0,
        ...cardProps.sx,
      }}
    >
      <Box
        sx={{ display: "flex", width: "100%", flexDirection: "column", p: 0 }}
      >
        {(startSlot || endSlot || title) && (
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 0,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {startSlot}
                {title && (
                  <Typography variant="h5" sx={titleProps.sx}>
                    {title}
                  </Typography>
                )}
              </Box>
              {endSlot}
            </Box>
          </Box>
        )}
        <Box sx={{ px: 2 }}>
          {infoText && (
            <Box
              sx={{
                background: "#f5f5f5",
                color: "#2B454E",
                width: "100%",
                maxHeight: 400,
                overflow: "auto",
                fontStyle: "italic",
                borderRadius: "12px",

                px: 2,
                mb: 2,
              }}
            >
              <Markdown>{infoText}</Markdown>
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default IneffectiveControlsComponentTitleCard;
