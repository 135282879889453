import {
  Box,
  BoxProps,
  Card,
  CardProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

type IndentedTextCardWithTitleProps = {
  title?: string;
  text?: { line: string; indent: boolean }[];
  cardProps?: CardProps;
  textProps?: BoxProps;
  titleProps?: TypographyProps;
  link?: string;
  linkText?: string;
};

export const IndentedTextCardWithTitle: PimoReactComponent<
  IndentedTextCardWithTitleProps
> = ({
  title,
  text,
  cardProps = {},
  textProps = {},
  titleProps = {},
  link,
  linkText,
}) => {
  return (
    <Card
      data-testid="DonutChart"
      title={title}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: "12px",
        boxShadow: "none",
        flex: "1 1 100%",
        p: 2,
        gap: {
          xs: 2,
          xl: 0,
        },
        paddingX: 2,
        paddingY: 1,
        minWidth: 250,
        backgroundColor: "#f5f5f5",
        mx: 2,
        mt: 2,
        ...cardProps?.sx,
        "@media print": {
          pt: 2,
        },
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{
            textAlign: "left",
            fontWeight: 500,
            fontSize: "16px",
            ...titleProps.sx,
          }}
        >
          {title}
        </Typography>

        <Box {...textProps}>
          {text?.map((line) => {
            return (
              <Typography
                sx={{
                  textAlign: "left",
                  fontWeight: 400,
                  fontSize: "14px",
                  marginLeft: line.indent ? "20px" : "0px",
                }}
              >
                {line.line}
              </Typography>
            );
          })}
          {link && (
            <a href={link} target="_blank">
              {linkText}
            </a>
          )}
        </Box>
      </Box>
    </Card>
  );
};
