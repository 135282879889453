import { GroupDashboard, OeProject } from "rr-types";
import { STRAPI_URL } from "../env";

export async function fetchDashboard(): Promise<{
  dashboardData: GroupDashboard;
  oes: OeProject[];
}> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff-dashboard`, {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as {
      dashboardData: GroupDashboard;
      oes: OeProject[];
    };

    return json;
  } catch {
    return {} as { dashboardData: GroupDashboard; oes: OeProject[] };
  }
}
