import { Box, Typography } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

export type SeparateHorizontalLegendProps = {
  title: string;
  entries: { title: string; color: string }[];
};

export const SeparateHorizontalLegend: PimoReactComponent<
  SeparateHorizontalLegendProps,
  undefined,
  undefined
> = ({ title, entries }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "90%",
        justifyContent: "space-evenly",
        p: 2,
      }}
    >
      <Typography sx={{ fontWeight: 500 }}>{title}</Typography>
      {entries.map((entry) => (
        <Box
          key={entry.title}
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
        >
          <Box
            sx={{
              backgroundColor: entry.color,
              borderRadius: "100%",
              height: 15,
              width: 15,
            }}
          />
          <Typography>{entry.title}</Typography>
        </Box>
      ))}
    </Box>
  );
};
