import { Box, Card, CardProps, Typography } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import type { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

import { Markdown } from "../../molecules";

export type GroupedBarChartProps = {
  title?: string;
  categories?: string[];
  colors?: string[];
  groupedSeries?: {
    background?: string;
    series: { name: string; value: number[] }[];
    categories?: string[];
  }[];

  titleOfTopText?: string;
  sideText?: string;
  link?: string;
  linkText?: string;
  cardProps?: CardProps;
  background?: string;
  maxChartWidth?: number;
  categoriesColors?: string[];
  fallbackText?: string;
  labelsColors?: string[];
};

export const GroupedBarChart: PimoReactComponent<GroupedBarChartProps> = ({
  groupedSeries,
  categories,
  title,
  colors,
  titleOfTopText,
  sideText,
  cardProps,
  background,
  maxChartWidth,
  categoriesColors,
  fallbackText,
  link,
  linkText,
  labelsColors,
}) => {
  const options: ApexOptions = {
    chart: {
      type: "bar",
      background: "none",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    colors: colors,
    grid: {
      show: false,
    },
    stroke: {
      colors: ["transparent"],
      width: 2,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: labelsColors ? labelsColors : ["#fff"],
        fontWeight: 500,
      },
    },
    legend: { show: false },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: 15,
      },
    },

    xaxis: {
      categories: categories,
      axisBorder: { show: false },
      axisTicks: { show: false },
      labels: {
        show: false,
        style: {
          colors: "#000",
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      labels: {
        maxWidth: 300,
        style: {
          colors: categoriesColors,
          fontWeight: 500,
          fontSize: "16px",
        },
      },
    },
    tooltip: {
      fillSeriesColor: false,
      theme: "light",
    },
  };

  return (
    <Card
      {...cardProps}
      data-testid="StackedBarChart"
      title={title}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: background,
        boxShadow: "none",
        flex: "1 1 100%",
        height: "100%",
        width: "100%",
        borderRadius: 12,
        p: 2,
        gap: {
          xs: 2,
          xl: 0,
        },
        minWidth: 250,
        "@media print": {
          pt: 2,
        },
        ...cardProps?.sx,
      }}
    >
      {(titleOfTopText || sideText) && (
        <Box>
          {titleOfTopText && (
            <Typography
              sx={{ textAlign: "left", fontWeight: 500, fontSize: "16px" }}
            >
              {titleOfTopText ?? ""}
            </Typography>
          )}
          <Box sx={{ maxHeight: 250, overflow: "auto" }}>
            {sideText && <Markdown>{sideText}</Markdown>}
            {link && (
              <a href={link} target="_blank">
                {linkText}
              </a>
            )}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        {title && (
          <Typography
            sx={{ textAlign: "left", fontWeight: 500, fontSize: "16px" }}
          >
            {title}
          </Typography>
        )}
        {groupedSeries
          ?.flatMap((e) => e.series)
          .flatMap((s) => s.value)
          .some((e) => e) ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flex: "1 1 100%",
              maxWidth: 250,
            }}
          >
            {groupedSeries?.map((gs, index) => (
              <Box
                key={`${index}groupedBarChart`}
                sx={{
                  background: gs.background,
                  borderRadius: 12,
                  p: 0,
                }}
              >
                <ReactApexChart
                  type="bar"
                  width={maxChartWidth ?? 250}
                  height={230}
                  options={{
                    ...options,
                    xaxis: {
                      ...options.xaxis,
                      categories: gs.categories ?? categories,
                    },
                  }}
                  series={gs.series.map((s) => ({
                    name: s.name,
                    data: s.value,
                    group: "test",
                  }))}
                ></ReactApexChart>
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              color: "grey",
              verticalAlign: "center",
              textAlign: "center",
              display: "flex",
              flex: "1 1 100%",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ fontSize: 18 }}> {fallbackText}</Typography>
          </Box>
        )}
      </Box>
    </Card>
  );
};
