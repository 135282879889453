import {
  Box,
  BoxProps,
  Card,
  CardProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

import { Markdown } from "../../molecules";

type SimpleTextCardWithTitleProps = {
  title?: string;
  text?: string;
  cardProps?: CardProps;
  textProps?: BoxProps;
  titleProps?: TypographyProps;
  link?: string;
  linkText?: string;
};

export const SimpleTextCardWithTitle: PimoReactComponent<
  SimpleTextCardWithTitleProps
> = ({
  title,
  text,
  cardProps = {},
  textProps = {},
  titleProps = {},
  link,
  linkText,
}) => {
  return (
    <Card
      data-testid="DonutChart"
      title={title}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: "12px",
        boxShadow: "none",
        flex: "1 1 100%",
        p: 2,
        gap: {
          xs: 2,
          xl: 0,
        },
        paddingX: 2,
        paddingY: 1,
        minWidth: 250,
        ...cardProps?.sx,
        "@media print": {
          pt: 2,
        },
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{
            textAlign: "left",
            fontWeight: 500,
            fontSize: "16px",
            ...titleProps.sx,
          }}
        >
          {title}
        </Typography>

        <Box {...textProps}>
          <Markdown fontSizeRelativeTo="parent">{text ?? ""}</Markdown>
          {link && (
            <a href={link} target="_blank">
              {linkText}
            </a>
          )}
        </Box>
      </Box>
    </Card>
  );
};
