import { Box, BoxProps, Card, Chip, Typography, useTheme } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

import { ProgressBar } from "../../molecules";
import DonutChart, {
  DonutChartWithSideTextProps,
} from "../donut-chart-with-side-text/donut-chart-with-side-text";
import { SimpleTextCardWithTitle } from "../simple-text-card-with-title/simple-text-card-with-title";

export type CompositeCardWithChartProps = {
  cardProps?: BoxProps;
  headers: {
    title?: string;
    chipText?: string;
    chipColor?: string;
    subtitle?: string;
    link?: string;
    linkDesplayText?: string;
  }[];
  footerSectionTitle?: string;
  footerSectionText?: string;
  donutChartDetails?: DonutChartWithSideTextProps[];
  progressBarDetails?: { label: string; value: number };
};

export const CompositeCardWithChart: PimoReactComponent<
  CompositeCardWithChartProps
> = ({
  cardProps = {},
  headers,
  footerSectionTitle,
  footerSectionText,
  donutChartDetails,
  progressBarDetails,
}) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",
        gap: 2,
        boxShadow: "none",
        p: 2,
        width: "50%",
        ...cardProps.sx,
      }}
    >
      {headers?.map((header, index) => (
        <Box key={`${header?.title ?? "title"}${index}`}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            {header?.title && (
              <Typography sx={{ fontWeight: 500 }}>{header.title}</Typography>
            )}
            {header?.chipText && (
              <Chip
                label={header?.chipText}
                sx={{
                  background: header.chipColor ?? theme.palette.primary.main,
                  color: "white",
                  fontWeight: 500,
                }}
              ></Chip>
            )}
          </Box>
          {header?.subtitle && <Typography>{header.subtitle}</Typography>}
          {header.link && (
            <a href={header.link} target="_blank">
              {header.linkDesplayText}
            </a>
          )}
        </Box>
      ))}

      {donutChartDetails &&
        donutChartDetails.map((donutChartDetail, index) => (
          <Box key={`${index}DonutChart`}>
            <DonutChart {...donutChartDetail}></DonutChart>
          </Box>
        ))}
      {progressBarDetails && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography sx={{ fontWeight: 500 }}>
            {progressBarDetails.label}
          </Typography>
          <ProgressBar
            progress={progressBarDetails.value}
            progressColor={"#94CADC"}
            progressBarHeight="15px"
          />
        </Box>
      )}
      {(footerSectionTitle || footerSectionText) && (
        <SimpleTextCardWithTitle
          cardProps={{
            sx: { background: theme.palette.secondary.main, maxHeight: 120 },
          }}
          titleProps={{ sx: { fontSize: "16px", fontWeight: 500 } }}
          title={footerSectionTitle}
          text={footerSectionText}
        ></SimpleTextCardWithTitle>
      )}
    </Card>
  );
};
