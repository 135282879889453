import {
  BoxProps,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

export type ListCardContentProps = {
  entries: { title: string; cellProps?: BoxProps }[][];
  cardProps?: BoxProps;
};

export const ListCardContent: PimoReactComponent<ListCardContentProps> = ({
  entries,
  cardProps,
}) => {
  return (
    <TableContainer sx={cardProps?.sx} component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          {entries.map((row, index) => (
            <TableRow
              key={`${index}listCardContent${row[0]?.title ?? ""}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {row?.map((cell) => (
                <TableCell
                  key={`${index}listCardCell${cell?.title ?? ""}`}
                  sx={cell.cellProps?.sx}
                  align="left"
                >
                  {cell.title}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
