import { Cache } from "@pimo/pimo-app-builder";
import { STRAPI_URL } from "../env";
import { OeProject } from "rr-types";

const cache = new Cache();

export async function fetchOE({ id }: { id: string }): Promise<OeProject> {
  const response = await cache.fetch(`${STRAPI_URL}/api/bff-oe-project/${id}`, {
    credentials: "include",
  });

  if (response.status >= 400) {
    throw new Error(`Fetch failed with status ${response.status}.`);
  }

  const json = (await response.json()) as OeProject;
  return json;
}
