import { Box, BoxProps } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

export type ImageCardProps = {
  src: string;
  cardProps?: BoxProps;
};

export const ImageCard: PimoReactComponent<ImageCardProps> = ({
  src,
  cardProps,
}) => {
  return <Box component="img" {...cardProps} src={src} />;
};
