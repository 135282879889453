/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Box, Card, CardProps, Typography } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

import { Markdown } from "../../molecules";

export interface DonutChartWithSideTextProps {
  /** Global Title of the Card, displayed to the left */
  title?: string;
  /** Value of series */
  series: number[];
  /** Chart Title of the Graphic, displayed to the bottom */
  chartTitle?: string;
  /** Chart's colors */
  backgroundColor: string[];
  legendIcons?: string[];
  /** Chart's colors */
  labels?: readonly string[];
  /**
   * colors schemes for the label badges, if this property is passed
   * the legend on the side will render badges instead of simple text
   */
  labelBadgesColors?: { chipColor: string; textColor: string }[];
  /** Chart's tooltips Labels */
  toolTipLabels?: readonly string[];
  toolTipEnabled?: boolean;
  /** show labels inside chart */
  showLabels?: boolean;
  /** enable hover effects over chart */
  enableHover?: boolean;
  /** (optional) more styling props */
  cardProps?: CardProps;
  /** fallback text */
  fallbackText?: string;
  /** footer text  */
  footerText?: string;
  /** offset labels */
  labelsOffset?: number;
  /** value to be displayed in center of donut */
  centerText?: string;
  /** title of side text */
  titleOfSideText?: string;
  /** side text content */
  sideText?: string;
  /** card background color */
  background?: string;
  /** lables title */
  labelsTitle?: string;
  /** enable data labels */
  enableDataLabels?: boolean;
  enableLegend?: boolean;
}

export const DonutChart: PimoReactComponent<DonutChartWithSideTextProps> = ({
  title,
  series,
  backgroundColor,
  labels,
  toolTipLabels = [],
  showLabels,
  toolTipEnabled,
  enableHover,
  cardProps = {},
  labelBadgesColors,
  legendIcons,
  labelsTitle,
  fallbackText,
  footerText,
  labelsOffset,
  centerText,
  sideText,
  titleOfSideText,
  background,
  enableDataLabels,
  enableLegend,
}) => {
  const options: ApexOptions = {
    labels: toolTipLabels as unknown as string[],
    colors: backgroundColor,
    legend: {
      show: enableLegend ?? false,
      formatter: function (seriesName, opts) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
        return [
          seriesName,
          " - ",
          opts.w.globals.series[opts.seriesIndex],
        ].join("");
      },
    },
    chart: {
      animations: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: enableDataLabels,
      formatter: function (_, opts) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
        return opts.w.config.series[opts.seriesIndex];
      },

      style: {
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        colors: ["black"],
      },
      dropShadow: {
        enabled: false,
      },
    },
    tooltip: {
      enabled: toolTipEnabled,
      fillSeriesColor: false,
      theme: "light",
    },
    plotOptions: {
      pie: {
        dataLabels: {
          minAngleToShowLabel: 1,
          offset: labelsOffset ?? 0,
        },
        customScale: 0.9,
        offsetY: 20,
        offsetX: 5,
        donut: {
          size: "70%",

          labels: {
            show: showLabels ?? true,
            total: {
              show: true,
              showAlways: true,
              label: centerText ?? "Total:",
              fontSize: "16px",
              fontWeight: 700,
              fontFamily: "Roboto",
            },
          },
        },
      },
    },
  };

  if (!enableHover) {
    options.states = {
      hover: {
        filter: {
          type: "none",
        },
      },
    };
  }

  return (
    <Card
      {...cardProps}
      data-testid="DonutChart"
      title={title}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: background,
        boxShadow: "none",
        flex: "1 1 100%",
        borderRadius: "12px",
        p: 2,
        gap: {
          xs: 2,
          xl: 0,
        },
        minWidth: 250,
        "@media print": {
          pt: 2,
        },
      }}
    >
      {(titleOfSideText || sideText) && (
        <Box sx={{ width: "50%" }}>
          {titleOfSideText && (
            <Typography
              sx={{ textAlign: "left", fontWeight: 500, fontSize: "16px" }}
            >
              {titleOfSideText ?? ""}
            </Typography>
          )}
          <Box sx={{ maxHeight: 200, overflow: "auto" }}>
            {sideText && <Markdown>{sideText}</Markdown>}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flex: "1 1 100%",
          width: "50%",
        }}
      >
        {title && (
          <Typography
            sx={{ textAlign: "center", fontWeight: 500, fontSize: "16px" }}
          >
            {title}
          </Typography>
        )}
        {series.some((entry) => entry !== 0) ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            {labels && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  mx: "auto",
                  minWidth: 170,
                  "@media print": {
                    margin: "unset",
                  },
                }}
              >
                <Typography sx={{ fontWeight: 500 }}>{labelsTitle}</Typography>
                {labels?.map((label, index) =>
                  labelBadgesColors ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      key={index}
                    >
                      <Box
                        sx={{
                          lineHeight: "1.2rem",
                          fontSize: "12px",
                          height: "1.2rem",
                          textAlign: "center",
                          fontWeight: 500,
                          flex: "1 1 100%",
                          borderRadius: 12,
                          maxWidth: 90,
                          backgroundColor: labelBadgesColors[index].chipColor,
                          color: labelBadgesColors[index].textColor,
                        }}
                      >
                        {label}
                      </Box>
                      <Typography variant={"subtitle2"}>
                        {series[index]}
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "15px",
                      }}
                      key={index}
                    >
                      {legendIcons?.[index] ? (
                        <Box
                          component={"img"}
                          src={legendIcons[index]}
                          sx={{ marginLeft: -0.5 }}
                        />
                      ) : (
                        <Box
                          fontSize="small"
                          sx={{
                            borderRadius: "100%",
                            height: "15px",
                            width: "15px",
                            flex: "0 0 15px",

                            background: backgroundColor[index],
                            border:
                              backgroundColor[index].toUpperCase() === "#FFFFFF"
                                ? "#003781 1px solid"
                                : "",
                          }}
                        />
                      )}
                      <Typography
                        component="span"
                        sx={{ flex: "1 1 100%", maxWidth: 130 }}
                      >
                        {label}
                      </Typography>
                      {label && (
                        <Typography variant={"subtitle2"}>
                          {series[index]}
                        </Typography>
                      )}
                    </Box>
                  )
                )}
                {footerText && (
                  <Typography sx={{ p: 2, fontStyle: "italic" }}>
                    {footerText}
                  </Typography>
                )}
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flex: "1 1 100%",
              }}
            >
              <ReactApexChart
                options={options}
                series={series}
                type="donut"
                height={200}
                className="apex-charts"
              />
            </Box>
          </Box>
        ) : (
          <Box
            className="apex-charts"
            sx={{
              color: "grey",
              verticalAlign: "center",
              textAlign: "center",

              display: "flex",
              flex: "1 1 100%",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ fontSize: 26 }}> {fallbackText}</Typography>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default DonutChart;
