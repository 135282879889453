import {
  Tooltip as MUITooltip,
  type TooltipProps,
  useTheme,
} from "@mui/material";
import { type FC } from "react";

export const Tooltip: FC<TooltipProps> = ({
  children,
  placement = "right",
  title,
  ...props
}) => {
  const theme = useTheme();

  return (
    <MUITooltip
      {...props}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "white",
            "& .MuiTooltip-arrow": {
              color: "white",
            },
            color: theme.palette.text.primary,
            p: 1,
            px: 2,
            fontWeight: 400,
            fontSize: "1rem",
            backgroundColor: "background.paper",
            borderRadius: "6px",
            border: "1px solid",
            borderColor: theme.palette.secondary.dark,
            whiteSpace: "pre-wrap",
          },
        },
      }}
      placement={placement}
      title={title}
    >
      {children}
    </MUITooltip>
  );
};
