import {
  App,
  EventEmitter,
  Plugin,
  UniversalAppState,
  View,
} from "@pimo/pimo-app-builder";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HomePluginState {}

export class HomePlugin<AppState extends UniversalAppState>
  extends EventEmitter<"registered">
  implements Plugin<AppState, AppState>
{
  route?: Plugin<AppState, AppState>["route"];

  onRegister(app: App<AppState>): void {
    this.route = app.createRoute({
      path: "/",
      view: new View("home", { getLayoutComponent: () => () => null }),
    });
  }
}
