import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardProps,
  Chip,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import {
  CompositeCardWithChart,
  CompositeCardWithChartProps,
  DonutChart,
  DonutChartWithSideTextProps,
  SimpleTextCardWithTitle,
} from "@pimo/pimo-components";

type HealthIndicatorCardProps = {
  title: string;
  scoreChipText?: string;
  scoreChipColor?: string;
  subtitle?: string;
  riskAssessmentProps: CompositeCardWithChartProps;
  riskOwnershipProps: CompositeCardWithChartProps;
  timelyRiskManagementChipText?: string;
  timelyRiskManagementSubtitleRightSideText?: string;
  timelyRiskManagementSubtitleLeftSideText?: string;
  requiredActions?: string;
  delayDonutChartProps?: DonutChartWithSideTextProps;
  responseDonutChartProps?: DonutChartWithSideTextProps;
  mitigationDonutChartProps?: DonutChartWithSideTextProps;
  overdueRequiredActions?: string;
  cardProps?: CardProps;
};

export const HealthIndicatorCard: PimoReactComponent<
  HealthIndicatorCardProps
> = ({
  title,
  subtitle,
  scoreChipColor,
  scoreChipText,
  riskAssessmentProps,
  riskOwnershipProps,
  timelyRiskManagementChipText,
  timelyRiskManagementSubtitleLeftSideText,
  timelyRiskManagementSubtitleRightSideText,
  delayDonutChartProps,
  responseDonutChartProps,
  mitigationDonutChartProps,
  requiredActions,
  overdueRequiredActions,
  cardProps,
}) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        boxShadow: "none",
        py: 2,
        width: "100%",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        "@media print": {
          border: "1px solid rgba(0, 0, 0, 0.12)",
          container: {
            displayPrint: "inline",
          },
        },
        gap: 2,
        ...cardProps?.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          px: 2,
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            {title}
          </Typography>

          {scoreChipText && (
            <Chip
              label={scoreChipText}
              sx={{
                background: scoreChipColor,
                fontWeight: 500,
                color: "white",
              }}
            />
          )}
        </Box>
        {subtitle && (
          <Box
            sx={{
              background: "#f5f5f5",
              color: "#2B454E",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              fontStyle: "italic",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "row",
              p: 1,
            }}
          >
            <Typography>{subtitle}</Typography>
            <Button id="scheme">
              <InfoOutlined />
            </Button>
          </Box>
        )}
      </Box>
      <Divider
        sx={{
          bgcolor: theme.palette.secondary.main,
          height: "1px",
          borderWidth: 0,
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: 2,
          px: 2,
        }}
      >
        <CompositeCardWithChart {...riskAssessmentProps} />
        <CompositeCardWithChart {...riskOwnershipProps} />
      </Box>
      <Box sx={{ px: 2 }}>
        <Box
          sx={{
            background: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            borderRadius: "12px",
            p: 2,
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography sx={{ fontWeight: 500 }}>
              {"Timely Risk Management"}
            </Typography>
            <Chip
              label={timelyRiskManagementChipText}
              sx={{
                background: theme.palette.primary.main,
                color: "white",
                fontWeight: 500,
              }}
            ></Chip>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
            }}
          >
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography>
                {timelyRiskManagementSubtitleLeftSideText}
              </Typography>
              {delayDonutChartProps && (
                <DonutChart {...delayDonutChartProps}></DonutChart>
              )}
              {requiredActions && (
                <SimpleTextCardWithTitle
                  cardProps={{
                    sx: {
                      background: theme.palette.secondary.main,
                      maxHeight: 120,
                    },
                  }}
                  titleProps={{ sx: { fontSize: "16px", fontWeight: 500 } }}
                  title={"Required Actions"}
                  text={requiredActions}
                ></SimpleTextCardWithTitle>
              )}
            </Box>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography>
                {" "}
                {timelyRiskManagementSubtitleRightSideText}
              </Typography>
              {responseDonutChartProps && (
                <DonutChart {...responseDonutChartProps}></DonutChart>
              )}
              {mitigationDonutChartProps && (
                <DonutChart {...mitigationDonutChartProps}></DonutChart>
              )}
              {overdueRequiredActions && (
                <SimpleTextCardWithTitle
                  cardProps={{
                    sx: {
                      background: theme.palette.secondary.main,
                      maxHeight: 120,
                    },
                  }}
                  titleProps={{ sx: { fontSize: "16px", fontWeight: 500 } }}
                  title={"Required Actions"}
                  text={overdueRequiredActions}
                ></SimpleTextCardWithTitle>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
