import { STRAPI_URL } from "../env";

export async function fetchOverlay(): Promise<
  | {
      oesForMenu: { name: string; id: number }[];
      regionsForMenu: { name: string; id: number }[];
      isAdmin: boolean;
    }
  | undefined
> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff-overlay`, {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as {
      oesForMenu: { name: string; id: number }[];
      regionsForMenu: { name: string; id: number }[];
      isAdmin: boolean;
    };
    return json;
  } catch (error) {
    return;
  }
}
