import {
  Box,
  BoxProps,
  CardProps,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

import { ChipCell } from "../../molecules";

export type TableWithChipHeadersProps = {
  headers: { title: string; cellProps?: CardProps }[];
  entries: { title: string; cellProps?: BoxProps }[][];
  cardProps?: BoxProps;
};

export const TableWithChipHeaders: PimoReactComponent<
  TableWithChipHeadersProps
> = ({ headers, entries }) => {
  return (
    <TableContainer sx={{ zoom: "85%" }} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                sx={{ maxWidth: 200 }}
                key={`${index}tableWithChipCell${header.title}`}
              >
                <ChipCell cardProps={header.cellProps} body={header.title} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map((row, index) => (
            <TableRow
              key={`${index}tableWithChipRow${row[index].title ?? ""}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {row.map((cell, celIndex) => (
                <TableCell
                  key={`${row[index].title}tableWithChipRowCell${
                    celIndex ?? ""
                  }}`}
                  sx={{ maxWidth: 200 }}
                  align="center"
                >
                  <Box sx={{ ...cell.cellProps?.sx }}>{cell.title}</Box>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
