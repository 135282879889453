import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { OeProject } from "rr-types";
import { HealthIndicatorCard } from "./health-indicator-card";
import { RISK_TYPE_DETAILS, getPercentage } from "rr-utils";
import { Box, useTheme } from "@mui/material";

export interface HealthIndicatorCardsInRegionProps {
  oes: OeProject[] | undefined;
}

const HealthIndicatorCardsInRegion: PimoReactComponent<
  HealthIndicatorCardsInRegionProps
> = ({ oes }) => {
  const theme = useTheme();
  if (!oes) return null;
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {oes.map((oe) => {
        const responseDecision = oe?.responseDecision;
        const score = oe?.score ?? 1;
        const colorForScoreChip =
          score === 5
            ? RISK_TYPE_DETAILS.verylow.color
            : score === 4
              ? RISK_TYPE_DETAILS.low.color
              : score === 3
                ? RISK_TYPE_DETAILS.moderate.color
                : score === 2
                  ? RISK_TYPE_DETAILS.high.color
                  : RISK_TYPE_DETAILS.veryHigh.color;

        const status = oe?.status ?? "";

        const totalIncorrectOwnership =
          (oe?.currentQuarterOwnership?.ba ?? 0) +
          (oe?.currentQuarterOwnership?.isms ?? 0) +
          (oe?.currentQuarterOwnership?.itom ?? 0) +
          (oe?.currentQuarterOwnership?.itService ?? 0);

        const totalNumberOfRisks = oe?.totalNumberOfRisks ?? 0;

        const totalDelayedRisks =
          (oe?.currentQuarterDelayed?.overall?.high ?? 0) +
          (oe?.currentQuarterDelayed?.overall?.moderate ?? 0) +
          (oe?.currentQuarterDelayed?.overall?.low ?? 0) +
          (oe?.currentQuarterDelayed?.overall?.veryLow ?? 0) +
          (oe?.currentQuarterDelayed?.overall?.veryHigh ?? 0) +
          (oe?.currentQuarterDelayed?.overall?.noSelection ?? 0);

        const totalOverdueRisks =
          (oe?.overallOverdueMitigation?.veryHigh
            ?.map((r) => r.count)
            ?.reduce((acc, curr) => acc + curr, 0) ?? 0) +
          (oe?.overallOverdueMitigation?.high
            ?.map((r) => r.count)
            ?.reduce((acc, curr) => acc + curr, 0) ?? 0) +
          (oe?.overallOverdueMitigation?.moderate
            ?.map((r) => r.count)
            ?.reduce((acc, curr) => acc + curr, 0) ?? 0) +
          (oe?.overallOverdueMitigation?.low
            ?.map((r) => r.count)
            ?.reduce((acc, curr) => acc + curr, 0) ?? 0) +
          (oe?.overallOverdueMitigation?.veryLow
            ?.map((r) => r.count)
            ?.reduce((acc, curr) => acc + curr, 0) ?? 0) +
          (oe?.overallOverdueMitigation?.noSelection
            ?.map((r) => r.count)
            ?.reduce((acc, curr) => acc + curr, 0) ?? 0);

        return (
          <HealthIndicatorCard
            key={oe?.oeName}
            title={`ISSR Health Indicator- ${oe?.oeName ?? ""}`}
            scoreChipColor={colorForScoreChip}
            scoreChipText={`Score: ${oe?.score ?? 1}`}
            cardProps={{
              sx: {
                "@media print": {
                  pageBreakBefore: "always",
                },
              },
            }}
            subtitle="ISRR Health Indicator monitors the maturity level of ISRR process as an integral component of the Integrated Information Risk Management (IIRM)"
            riskAssessmentProps={{
              headers: [
                {
                  title: "Inherent Risk Assessment",
                  chipText: "Weighted Score: 30%",
                  subtitle:
                    status === "Approved"
                      ? "ISMS Questionnaires have been approved"
                      : "ISMS Questionnaires have not been approved",
                },
                {
                  title: "ISMS Questionnaires",
                  chipText: status === "Approved" ? "Approved" : "Not Approved",
                  chipColor: "#78B8CD",
                },
              ],
              cardProps: {
                sx: {
                  background: "#f5f5f5",
                },
              },
              footerSectionTitle: "Required Actions",
              footerSectionText:
                status === "Approved"
                  ? "N/A"
                  : "Board member in charge of IS to approve ISMS Questionnaire.",
            }}
            riskOwnershipProps={{
              donutChartDetails: [
                {
                  labelsOffset: 22,
                  enableDataLabels: false,
                  series: [
                    totalIncorrectOwnership,
                    totalNumberOfRisks - totalIncorrectOwnership,
                  ],
                  backgroundColor: [
                    "#94CADC",
                    theme.palette.primary.main,
                    theme.palette.secondary.main,
                  ],

                  labels: ["Incorrect Ownership", "Correct Ownership"],
                  background: "#f5f5f5",
                  showLabels: true,
                  toolTipEnabled: true,
                  toolTipLabels: ["Incorrect Ownership", "Correct Ownership"],
                },
              ],
              footerSectionText:
                "Assign risks to the correct owner according to AFIRM.",
              footerSectionTitle: "Required Actions",
              cardProps: { sx: { width: "50%", background: "#f5f5f5" } },
              headers: [
                {
                  title: "Risk ownership",
                  chipText: "Weighted Score: 30%",
                  subtitle: `${totalIncorrectOwnership} out of ${totalNumberOfRisks} (${getPercentage(
                    totalNumberOfRisks,
                    totalIncorrectOwnership
                  )}%) IS risks have incorrect owners assigned.`,
                },
              ],
            }}
            overdueRequiredActions={
              "Review and mitigate risks within planned timeline."
            }
            timelyRiskManagementChipText={"Weighted Score: 40%"}
            timelyRiskManagementSubtitleLeftSideText={`${totalDelayedRisks} out of ${totalNumberOfRisks} (${getPercentage(
              totalNumberOfRisks,
              totalDelayedRisks
            )}%) IS risks are delayed in process, meaning not in monitoring and reporting within 30 calendar days from identification/re-assessment.`}
            timelyRiskManagementSubtitleRightSideText={`Among ${
              responseDecision?.mitigate ?? 0
            } IS risks with response decision “Mitigate”, ${totalOverdueRisks} (${getPercentage(
              responseDecision?.mitigate ?? 0,
              totalOverdueRisks
            )}%) risks have overdue Mitigation Plan. \n\n ${
              responseDecision?.noSelection ?? 0
            } risks have no response decision defined yet.`}
            requiredActions="Move all the identified risks to Monitoring & Reporting in a timely manner"
            delayDonutChartProps={{
              labelsOffset: 22,
              enableDataLabels: false,
              series: [
                totalDelayedRisks,
                oe.totalNumberOfRisks - totalDelayedRisks,
              ],
              labelsTitle: "Delay",
              backgroundColor: [theme.palette.primary.main, "#94CADC"],
              labels: ["Delayed Process", "On Track Process"],
              background: "#f5f5f5",
              showLabels: true,

              toolTipEnabled: true,
              toolTipLabels: ["Delayed Process", "On Track Process"],
            }}
            responseDonutChartProps={{
              labelsOffset: 22,
              enableDataLabels: false,
              series: [
                responseDecision?.noSelection ?? 0,
                responseDecision?.mitigate ?? 0,
                responseDecision?.accept ?? 0,
                responseDecision?.close ?? 0,
              ],
              labelsTitle: "Response Decision",
              backgroundColor: [
                responseDecision?.noSelection ? "#94CADC" : "",
                responseDecision?.mitigate ? theme.palette.primary.main : "",
                responseDecision?.accept ? theme.palette.secondary.main : "",
                responseDecision?.close ? "#466D7A" : "",
              ],
              labels: [
                responseDecision?.noSelection ? "No Decision" : "",
                responseDecision?.mitigate ? "Mitigate" : "",
                responseDecision?.accept ? "Accept" : "",
                responseDecision?.close ? "Close" : "",
              ],
              background: "#f5f5f5",
              showLabels: true,
              toolTipEnabled: true,
              toolTipLabels: ["No Decision", "Mitigate", "Accept", "Close"],
            }}
            mitigationDonutChartProps={{
              labelsOffset: 22,
              enableDataLabels: false,
              series: [
                totalOverdueRisks,
                (oe?.responseDecision?.mitigate ?? 0) - totalOverdueRisks,
              ],
              labelsTitle: "Mitigation",
              backgroundColor: [
                "#94CADC",
                theme.palette.primary.main,
                theme.palette.secondary.main,
              ],
              labels: ["Overdue", "On Track"],
              background: "#f5f5f5",
              showLabels: true,
              toolTipEnabled: true,
              toolTipLabels: ["Overdue", "On Track"],
            }}
          />
        );
      })}
    </Box>
  );
};

export default HealthIndicatorCardsInRegion;
