export const APP_ROUTES = {
  home: "/",
  dashboard: "/dashboard",
  login: "/login",
  regions: "/regions",
  report: "/reports",
  dashboardPdf: "/dashboard-pdf",
  regionPdf: "/region-pdf",
  oePdf: "/oe-pdf",
  noData: "/no-data",
} as const;

export const PAGE_SECTIONS = [
  "Introduction",
  "IS Risk Report Summary",
  "Aggregated view",
  "01 Ownership & Oversight",
  "02 Timely Risk Management",
  "03 Accepted Risk Portfolio",
  "04 Ineffective Controls",
  "Additional Attention Points",
  "Abbreviations",
];

export const DASHBOARD_PAGE_SECTIONS = [
  "Introduction",
  "IS Risk Report Summary",
  "Aggregated view",
  "01 Ownership & Oversight",
  "02 Timely Risk Management",
  "03 Accepted Risk Portfolio",
  "04 Ineffective Controls",
  "Additional Attention Points",
  "Questionnaire",
  "Abbreviations and Terms Used in This Report",
];

export const ABBREVIATIONS = [
  {
    term: "AFIRM",
    description: "Allianz Functional Rule for Information Risk Management",
  },
  { term: "IIRM", description: "Integrated Information Risk Management" },
  { term: "BA", description: "Business Applications" },
  { term: "ISRR", description: "Information Security Risk Response" },
  {
    term: "IS Risk",
    description:
      "Information risk arising from the absence, inadequate, or ineffective implementation of Information Security controls ",
  },
  { term: "ISMS", description: "Information Security Management System" },
  { term: "ITOM", description: "IT Operating Model" },
  { term: "OE", description: "Operating Entity" },
  {
    term: "Delayed process",
    description:
      "A risk that has not been moved to Monitoring & Reporting from the Identification or Reassessment date within 30 calendar days.",
  },
  {
    term: "Accepted Risk",
    description:
      'A risk that has a response decision "Accept," meaning it does not have a mitigation plan.',
  },
  {
    term: "M&R",
    description: "Monitoring and Reporting, which is a domain of IIRM",
  },
  { term: "Est.", description: "Estimated" },
];
