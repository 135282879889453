import type { App, Plugin, Route, View } from "@pimo/pimo-app-builder";

import type { RrAppState } from "../app";
import { CompoundLayout, CompoundLayoutProps } from "@pimo/pimo-components";
import { APP_ROUTES } from "../constants";
import { RegionPlugin } from "./region-plugin";

export class PDFRegionPlugin implements Plugin<RrAppState, RrAppState> {
  app?: App<RrAppState>;
  route?: Route;
  view?: View<RrAppState, CompoundLayoutProps>;
  isDashboardLoaded?: boolean;
  constructor(private regionPlugin: RegionPlugin) {}

  onRegister(app: App<RrAppState>): void {
    this.app = app;

    const view = app.createView({
      name: "pdf",
      layout: new CompoundLayout({
        areas: {
          title: { id: "title" },

          sections: [
            {
              header: { id: "introductionHeader" },
            },
            {
              components: [
                { id: "objectiveAndScopeTitle" },
                { id: "objectiveCard" },
                { id: "scopeCard" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: `"objectiveAndScopeTitle" "objectiveCard" "scopeCard"`,
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              components: [{ id: "oesTableTitle" }, { id: "regionsOesTable" }],
              style: {
                sx: {
                  gridTemplateAreas: `"oesTableTitle" "regionsOesTable"`,
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },

            {
              components: [
                { id: "functionalMonitoringTitle" },
                { id: "functionalMonitoringImage" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: `"functionalMonitoringTitle" "functionalMonitoringImage"`,
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },

            {
              components: [{ id: "kriTitle" }, { id: "kriImage" }],
              style: {
                sx: {
                  gridTemplateAreas: `"kriTitle" "kriImage"`,
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              components: [{ id: "iirmTitle" }, { id: "iirmImage" }],
              style: {
                sx: {
                  gridTemplateAreas: `"iirmTitle" "iirmImage"`,
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              components: [{ id: "threeStepTitle" }, { id: "threeStepImage" }],
              style: {
                sx: {
                  gridTemplateAreas: `"threeStepTitle" "threeStepImage"`,
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              header: { id: "ISRiskReportSummaryHeader" },
            },
            {
              components: [
                { id: "summaryTitle" },
                { id: "summaryTable" },
                { id: "summaryFootnote" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: `"summaryTitle" "summaryTable" "summaryFootnote"`,
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },

            {
              header: { id: "healthIndicatorCard" },
            },
            {
              header: { id: "aggregateHeader" },
            },
            {
              components: [
                { id: "allISRiskCardTitle" },
                { id: "allOaRCard" },
                { id: "ISMSOaRCard" },
                { id: "BAOaRCard" },
                { id: "ITOMOaRCard" },
                { id: "IT-ServiceOaRCard" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: `"allISRiskCardTitle allISRiskCardTitle" "allOaRCard allOaRCard" "ISMSOaRCard BAOaRCard" "ITOMOaRCard IT-ServiceOaRCard"`,
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              components: [
                { id: "allISCurrentRiskCard" },
                { id: "allISCurrentRiskCardTitle" },
                { id: "ISMSOaRCurrentRiskCard" },
                { id: "BaOaRCurrentRiskCard" },
                { id: "ITOMOaRCurrentRiskCard" },
                { id: "ServiceOaRCurrentRiskCard" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: `"allISCurrentRiskCardTitle allISCurrentRiskCardTitle" "allISCurrentRiskCard allISCurrentRiskCard" "ISMSOaRCurrentRiskCard BaOaRCurrentRiskCard" "ITOMOaRCurrentRiskCard ServiceOaRCurrentRiskCard"`,
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              header: { id: "ownershipHeader" },
            },
            {
              components: [
                { id: "ownershipKeyMessage" },
                { id: "ownershipCardTitle" },
                { id: "ismsOarChart" },
                { id: "baOarChart" },
                { id: "itomOarChart" },
                { id: "serviceOarChart" },
                { id: "legendOwnershipRisks" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "ownershipCardTitle ownershipCardTitle ownershipCardTitle" "ownershipKeyMessage ismsOarChart baOarChart" "ownershipKeyMessage itomOarChart serviceOarChart" "legendOwnershipRisks legendOwnershipRisks legendOwnershipRisks"`,
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              header: { id: "timelyRiskManagementHeader" },
            },
            {
              components: [
                { id: "timelyRiskCardTitle" },
                { id: "timelyRiskOaRChart" },
                { id: "timelyRiskismsOaRChart" },
                { id: "timelyRiskBaOaRChart" },
                { id: "timelyRiskItomOaRChart" },
                { id: "timelyRiskServiceOaRChart" },
                { id: "timelyRiskLegend" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "timelyRiskCardTitle timelyRiskCardTitle timelyRiskCardTitle" "timelyRiskOaRChart timelyRiskismsOaRChart timelyRiskBaOaRChart" "timelyRiskOaRChart timelyRiskItomOaRChart timelyRiskServiceOaRChart" "timelyRiskLegend timelyRiskLegend timelyRiskLegend"`,
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              components: [
                { id: "timelyOverdueRiskCardTitle" },
                { id: "allISOverdueRiskChart" },
                { id: "overdueRiskismsOaRChart" },
                { id: "overdueRiskBaOaRChart" },
                { id: "overdueRiskItomOaRChart" },
                { id: "overdueRiskServiceOaRChart" },
                { id: "overdueRiskLegend" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "timelyOverdueRiskCardTitle timelyOverdueRiskCardTitle timelyOverdueRiskCardTitle" "allISOverdueRiskChart overdueRiskismsOaRChart overdueRiskBaOaRChart" "allISOverdueRiskChart overdueRiskItomOaRChart overdueRiskServiceOaRChart" "overdueRiskLegend overdueRiskLegend overdueRiskLegend"`,
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              header: { id: "acceptedRiskManagementHeader" },
            },
            {
              components: [
                { id: "acceptedRiskCardTitle" },
                { id: "acceptedAllISRiskChart" },
                { id: "acceptedRiskIsmsOaRChart" },
                { id: "acceptedBaOaRChart" },
                { id: "acceptedRiskItomOaRChart" },
                { id: "acceptedRiskServiceOaRChart" },
                { id: "acceptedRiskLegend" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "acceptedRiskCardTitle acceptedRiskCardTitle acceptedRiskCardTitle" "acceptedAllISRiskChart acceptedRiskIsmsOaRChart acceptedBaOaRChart" "acceptedAllISRiskChart acceptedRiskItomOaRChart acceptedRiskServiceOaRChart" "acceptedRiskLegend acceptedRiskLegend acceptedRiskLegend"`,
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              header: { id: "ineffectiveControlsHeader" },
            },
            {
              components: [
                { id: "ineffectiveControlsCardTitle" },
                { id: "overviewNumberOfRisksCard" },
                { id: "firstRiskCard" },
                { id: "secondRiskCard" },
                { id: "ineffectiveControlsRisks" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "ineffectiveControlsCardTitle ineffectiveControlsCardTitle" "overviewNumberOfRisksCard overviewNumberOfRisksCard" "firstRiskCard secondRiskCard" "ineffectiveControlsRisks ineffectiveControlsRisks"`,
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              header: { id: "additionalPointsHeader" },
            },
            {
              components: [
                { id: "risksAssessmentCyclesCardTitle" },
                { id: "allIsRisksReassesmentCard" },
                { id: "ismsOaRCardReassessment" },
                { id: "baOaRCardReassessment" },
                { id: "itomOaRCardReassessment" },
                { id: "serviceOaRCardReassessment" },
                { id: "legendCardReassessment" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "risksAssessmentCyclesCardTitle risksAssessmentCyclesCardTitle risksAssessmentCyclesCardTitle" "allIsRisksReassesmentCard ismsOaRCardReassessment baOaRCardReassessment" "allIsRisksReassesmentCard itomOaRCardReassessment serviceOaRCardReassessment" "legendCardReassessment legendCardReassessment legendCardReassessment"`,
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              components: [
                { id: "risksFirstPublishedCardTitle" },
                { id: "allIsRisksFirstPublishedCard" },
                { id: "ismsOaRCardFirstPublished" },
                { id: "baOaRCardFirstPublished" },
                { id: "itomOaRCardFirstPublished" },
                { id: "serviceOaRCardFirstPublished" },
                { id: "legendCardFirstPublished" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "risksFirstPublishedCardTitle risksFirstPublishedCardTitle risksFirstPublishedCardTitle" "allIsRisksFirstPublishedCard ismsOaRCardFirstPublished baOaRCardFirstPublished" "allIsRisksFirstPublishedCard itomOaRCardFirstPublished serviceOaRCardFirstPublished" "legendCardFirstPublished legendCardFirstPublished legendCardFirstPublished"`,
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              components: [
                { id: "risksEstimatedCompletionCardTitle" },
                { id: "allIsRisksEstimatedCompletionCard" },
                { id: "ismsOaRCardEstimatedCompletion" },
                { id: "baOaRCardEstimatedCompletion" },
                { id: "itomOaRCardEstimatedCompletion" },
                { id: "serviceOaRCardEstimatedCompletion" },
                { id: "legendCardEstimatedCompletion" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "risksEstimatedCompletionCardTitle risksEstimatedCompletionCardTitle risksEstimatedCompletionCardTitle" "allIsRisksEstimatedCompletionCard ismsOaRCardEstimatedCompletion baOaRCardEstimatedCompletion" "allIsRisksEstimatedCompletionCard itomOaRCardEstimatedCompletion serviceOaRCardEstimatedCompletion" "legendCardEstimatedCompletion legendCardEstimatedCompletion legendCardEstimatedCompletion"`,
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              components: [
                { id: "risksAcceptedResponseCardTitle" },
                { id: "allIsRisksAcceptedResponseCard" },
                { id: "ismsOaRCardAcceptedResponse" },
                { id: "baOaRCardAcceptedResponse" },
                { id: "itomOaRCardAcceptedResponse" },
                { id: "serviceOaRCardAcceptedResponse" },
                { id: "legendCardAcceptedResponse" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "risksAcceptedResponseCardTitle risksAcceptedResponseCardTitle risksAcceptedResponseCardTitle" "allIsRisksAcceptedResponseCard ismsOaRCardAcceptedResponse baOaRCardAcceptedResponse" "allIsRisksAcceptedResponseCard itomOaRCardAcceptedResponse serviceOaRCardAcceptedResponse" "legendCardAcceptedResponse legendCardAcceptedResponse legendCardAcceptedResponse"`,
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              header: { id: "abbreviationSectionHeader" },
            },
            {
              components: [
                { id: "abbreviationsTitle" },
                { id: "abbreviationsTable" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: `"abbreviationsTitle" "abbreviationsTable"`,
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
          ],
        },
        loadingScreenTimeout: 0,
      }),
    });
    this.view = view;
    this.route = app.createRoute({
      isRenderingAllChildren: true,
      path: APP_ROUTES.regionPdf,
      view,
    });
  }

  render() {
    if (!this.view || !this.app) {
      return;
    }
    this.regionPlugin.buildDashboardPage(this.view);
  }
}
