import { Box, Card, CardProps, Typography } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

import { Markdown, Tooltip } from "../../molecules";

export type HorizontalBarChartProps = {
  title: string;
  cardProps?: CardProps;
  titleOfSideText?: string;
  sideText?: string;
  groupedSeries?: {
    series?: {
      labelsHeader?: string;
      entries: { value: number; color: string; label?: string }[];
    }[];
    backgroundColor?: string;
  }[];
  fallbackText?: string;
  /** a number to set the maximum for the bars, its hard to tell how long a bar should be beforehand
   * this value is set to the largest possible entry, and then all other bar lengths will be set to the appropriate
   * ratio of their value to the largest value
   */
  barMaxValue?: number;
  /**
   * extra prop to enable some text ot be larger in width, for UI reasons
   */
  textMaxWidth?: number;
};

export const HorizontalBarChart: PimoReactComponent<
  HorizontalBarChartProps
> = ({
  title,
  cardProps,
  titleOfSideText,
  sideText,
  groupedSeries,
  fallbackText,
  barMaxValue = 0,
  textMaxWidth,
}) => {
  return (
    <Card
      {...cardProps}
      data-testid="DonutChart"
      title={title}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        boxShadow: "none",
        flex: "1 1 100%",
        borderRadius: "12px",
        p: 2,
        gap: 9,
        minWidth: 250,
        width: "100%",
        ...cardProps?.sx,
        "@media print": {
          pt: 2,
        },
      }}
    >
      {(titleOfSideText || sideText) && (
        <Box>
          {titleOfSideText && (
            <Typography
              sx={{ textAlign: "left", fontWeight: 500, fontSize: "16px" }}
            >
              {titleOfSideText ?? ""}
            </Typography>
          )}
          <Box sx={{ maxHeight: 200, overflow: "auto" }}>
            {sideText && <Markdown>{sideText}</Markdown>}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {title && (
          <Typography
            sx={{
              textAlign: "start",
              width: "100%",
              fontWeight: 500,
              fontSize: "16px",
            }}
          >
            {title}
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "left",
          }}
        >
          {groupedSeries
            ?.flatMap((s) => s.series)
            .flatMap((s) => s?.entries.map((e) => e.value))
            .reduce((acc, curr) => (acc ?? 0) + (curr ?? 0), 0) ? (
            groupedSeries?.map((gs, index) => (
              <Box
                key={`${index}${
                  gs.series?.[index]?.entries?.[index]?.value ?? ""
                }groupedHorizontalChartSeries`}
                sx={{
                  background: gs.backgroundColor,
                  borderRadius: "12px",
                  p: 0.5,
                }}
              >
                {gs.series?.length && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      gap: "10px",
                      height: "100%",
                      "@media print": {
                        margin: "unset",
                      },
                    }}
                  >
                    {gs.series?.map((entry, index) => (
                      <Box
                        key={`${index}series${entry.labelsHeader ?? ""}`}
                        sx={{
                          gap: 1,
                          alignItems: "flex-start",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "left",
                        }}
                      >
                        {entry?.labelsHeader && (
                          <Typography sx={{ minWidth: 100 }}>
                            {entry.labelsHeader}
                          </Typography>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            {entry.entries.map((e, index) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  py: 0.5,
                                  "@media print": {
                                    margin: "unset",
                                  },
                                }}
                                key={`${e.value}${index}value`}
                              >
                                {e?.label && (
                                  <Tooltip title={e?.label}>
                                    <Typography
                                      sx={{
                                        fontWeight: 400,
                                        width: textMaxWidth ?? 100,
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        mr: 4,
                                      }}
                                    >
                                      {e.label}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Box>
                            ))}
                          </Box>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            {entry.entries.map((e, index) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1,
                                  "@media print": {
                                    margin: "unset",
                                  },
                                }}
                                key={`${index}value`}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 2,
                                    p: 0.5,

                                    alignItems: "center",
                                    "@media print": {
                                      margin: "unset",
                                    },
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: `${
                                        e.value * (90 / barMaxValue)
                                      }px`,
                                      backgroundColor: e.color,
                                      height: "4px",

                                      borderRadius: "6px",
                                    }}
                                  />
                                  {e.value !== undefined && (
                                    <Box>{e.value}</Box>
                                  )}
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            ))
          ) : (
            <Box
              sx={{
                color: "grey",
                verticalAlign: "center",
                textAlign: "center",
                display: "flex",
                flex: "1 1 100%",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontSize: 18 }}> {fallbackText}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  );
};
